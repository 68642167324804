import './logo-wall.scss';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

class LogoWall {
    constructor (element) {
        this.$logoWall = element;
        this.slider = null;
        this.$sliderContainer = this.$logoWall.querySelector('[data-logo-wall="slider"]');
        this.sliderDesktop = false;

        if (this.$sliderContainer.querySelector('[data-logo-wall="desktop"]')) {
            this.sliderDesktop = true;
        }
    }

    initialize () {
        if (this.$sliderContainer) {
            if (this.sliderDesktop === true) {
                this.initSlider();
            } else {
                this.checkSize();
                this.setEvents();
            }
        }
    }

    setEvents () {
        [768, 320].forEach((bp) => {
            window.mediaMatch[bp].addEventListener('change', () => {
                this.checkSize();
            });
        });
    }

    checkSize () {
        if (window.mediaMatch[768].matches) {
            if (this.slider !== null) {
                this.destroySlider();
            }
        } else {
            if (this.slider === null) {
                this.initSlider();
            }
        }
    }

    initSlider () {
        this.slider = new Swiper(this.$sliderContainer, {
            modules: [Navigation, Pagination],
            slidesPerView: 'auto',
            speed: 500,
            resistanceRatio: 0,
            autoHeight: false,
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar'
            },
            navigation: {
                nextEl: this.$logoWall.querySelector('.slider__navigation-pagination-next'),
                prevEl: this.$logoWall.querySelector('.slider__navigation-pagination-prev')
            }
        });

        this.$logoWall.classList.add('logo-wall--swiper');
    }

    destroySlider () {
        if (this.slider !== null) {
            this.slider.destroy(true, true);
            this.slider = null;
            this.$logoWall.classList.remove('logo-wall--swiper');
        }
    }
}

export { LogoWall };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$logoWall = $context.querySelectorAll('[data-logo-wall="root"]');
        for (let i = 0; i < $$logoWall.length; i++) {
            if ($$logoWall[i].querySelector('[data-logo-wall="slider"]')) {
                const logoWallAPI = new LogoWall($$logoWall[i]);
                logoWallAPI.initialize();
            }
        }
    }
});
